import React, { useState } from "react";

export default function PartSelect({ parts, requirements: initialRequirements }) {
  const [requirements, setRequirements] = useState([...initialRequirements]);
  const [deletedRequirements, setDeletedRequirements] = useState([]);

  const selectableParts = parts.filter((part) => {
    return (
      requirements.filter((requirement) => {
        return requirement.part_id === part.id;
      }).length === 0
    );
  });

  function addPart() {
    setRequirements([...requirements, { id: undefined, part_id: selectableParts[0].id, required_amount: 1 }]);
  }

  function removePart(removedRequirement) {
    const newRequirements = requirements.filter(
      (requirement) => removedRequirement.part_id !== requirement.part_id
    );
    if (removedRequirement.id) {
      setDeletedRequirements([...deletedRequirements, removedRequirement.id]);
    }
    setRequirements(newRequirements);
  }

  return (
    <div>
      {requirements.map((requirement, index) => {
        return (
          <div className="row" key={requirement.part_id}>
            <div className="col-md-7">
              <input
                type="hidden"
                value={requirement.id}
                name={`stock_keeping_unit[part_requirements_attributes][${index}][id]`}
                id={`stock_keeping_unit_part_requirements_attributes_${index}_id`}
              />
              <div className="form-group">
                <label htmlFor={`stock_keeping_unit_part_requirements_attributes_${index}_part_id`}>Part</label>
                <select
                  className="form-control"
                  defaultValue={requirement.part_id}
                  name={`stock_keeping_unit[part_requirements_attributes][${index}][part_id]`}
                  id={`stock_keeping_unit_part_requirements_attributes_${index}_part_id`}
                >
                  {[...parts.filter((part) => part.id === requirement.part_id), ...selectableParts].map((part) => (
                    <option key={part.id} value={part.id}>
                      {part.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor={`stock_keeping_unit_part_requirements_attributes_${index}_required_amount`}>
                  Required amount
                </label>
                <input
                  className="form-control"
                  type="number"
                  defaultValue={requirement.required_amount}
                  name={`stock_keeping_unit[part_requirements_attributes][${index}][required_amount]`}
                  id={`stock_keeping_unit_part_requirements_attributes_${index}_required_amount`}
                />
              </div>
            </div>
            <div className="col-md-1 d-flex justify-content-end align-items-center">
              <button type="button" className="btn btn-danger" onClick={() => removePart(requirement)}>
                Remove
              </button>
            </div>
          </div>
        );
      })}
      {deletedRequirements.map((deletedRequirement, index) => {
        const usedIndex = requirements.length + index;
        return (
          <div key={deletedRequirement}>
            <input
              type="hidden"
              value={deletedRequirement}
              name={`stock_keeping_unit[part_requirements_attributes][${usedIndex}][id]`}
              id={`stock_keeping_unit_part_requirements_attributes_${usedIndex}_id`}
            />
            <input
              type="hidden"
              value={deletedRequirement}
              name={`stock_keeping_unit[part_requirements_attributes][${usedIndex}][_destroy]`}
              id={`stock_keeping_unit_part_requirements_attributes_${usedIndex}__destroy`}
            />
          </div>
        );
      })}
      {selectableParts.length > 0 && (
        <button type="button" className="btn btn-primary mb-4" onClick={addPart}>
          Add part
        </button>
      )}
    </div>
  );
}
