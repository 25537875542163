import React from "react";

export default function CartLineItem({ product, lineItems, totalItems, totalPrice, updateCart }) {
  const productDescription = () => {
    return { __html: product.description };
  };

  const updateItem = (amount) => {
    updateCart({ code: product.code, amount: amount });
  };

  return (
    <div className="product row align-items-center mb-3">
      <div className="col-12 col-md-4 col-lg-2">
        <img src={product.product_image} />
      </div>
      <div className="col-12 col-md-8 col-lg-4">
        <h3>{product.name}</h3>
        <p dangerouslySetInnerHTML={productDescription()} />
        {product.specific_info && (
          <p className="hint">
            <i>{product.specific_info}</i>
          </p>
        )}
      </div>

      <div className="col-12 col-md-6 mt-5 mt-md-4 mt-lg-0 col-lg-3">
        <div className="amount">
          {product.single_item_per_order && (
            <input className="readonly" type="text" name={`amount[${product.code}]`} value={totalItems} readOnly />
          )}
          {!product.single_item_per_order && (
            <div className="amount-inputs">
              <span className="amount-label">Amount</span>
              <button type="button" className="less" onClick={() => updateItem(-1)}>
                -
              </button>
              <input pattern="[0-9]*" type="text" name={`amount[${product.code}]`} value={totalItems} readOnly />
              <button type="button" className="more" onClick={() => updateItem(1)}>
                +
              </button>
            </div>
          )}
        </div>
        <div className="amount ms-3">
          <button type="button" className="delete" onClick={() => updateItem(-totalItems)}>
            <i className="fas fa-trash-alt" style={{ color: "grey" }}></i>
          </button>
        </div>
      </div>

      <div className="col-12 col-md-6 col-lg-3 mt-3 mt-md-4 mt-lg-0 text-end">
        <div className="amount me-2">
          {lineItems.map((item) => (
            <div key={item.id}>
                  <span className="original-price">
                    {item.amount} x {item.price}
                  </span>
            </div>
          ))}
        </div>
        <div className="total-price text-end d-inline-flex">
          {lineItems.map((item) => (
            <div key={item.id}>
              <span className="original-price">{item.total_price}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
