import Rails from '@rails/ujs'

function validateResponse(res) {
  if (!res.ok) {
    const error = new Error(res.statusText)
    error.response = res
    throw error
  }

  return res
}

export function parseJson(res) {
  return res.json()
}

function validFetch(path, options = {}) {
  const fetchOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': Rails.csrfToken(),
      ...options.headers
    },
    credentials: 'same-origin',
    ...options
  }

  return fetch(path, fetchOptions).then(validateResponse)
}

export function fetchPost(path, body) {
  return validFetch(path, { method: 'POST', body: JSON.stringify(body) }).then(
    parseJson
  )
}
