import React, { useState } from "react";
import { fetchPost } from "../util/fetch";

function MembershipValidation({ countries, countriesWithVat, onValidationSuccess }) {
  const [country, setCountry] = useState("NL");
  const [vatNumber, setVatNumber] = useState("");
  const [knxMembershipNumber, setKnxMembershipNumber] = useState("");
  const [errors, setErrors] = useState({});

  const displayVatNumber = countriesWithVat.includes(country);

  const onSubmit = async (e) => {
    e.preventDefault();

    const errors = await fetchPost("/orders/validate_membership", {
      country: country,
      vat_number: vatNumber,
      knx_membership_number: knxMembershipNumber,
    });

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      onValidationSuccess();
    }
  };

  function hasErrors(field) {
    return errors[field] && errors[field].length > 0;
  }

  return (
    <form className="membership-form">
      <div className="row">
        <p className="col-md-4 offset-md-8">
          To order this Thinka we need your KNX membership number and depending on your country we also need your
          VAT number.
        </p>
      </div>
      <div className="row">
        <div className="form-group col-md-4 offset-md-8">
          <label>KNX membership number</label>
          <input
            type="text"
            value={knxMembershipNumber}
            onChange={(e) => setKnxMembershipNumber(e.target.value)}
            className={`form-control ${hasErrors("knx_membership_number") ? "is-invalid" : ""}`}
          />
          {errors.knx_membership_number && (
            <div className="invalid-feedback">{errors.knx_membership_number.join(", ")}</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-4 offset-md-8">
          <label>Country</label>
          <select
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            className={`form-control ${hasErrors("country") ? "is-invalid" : ""}`}
          >
            {countries.map((country) => {
              return (
                <option value={country[1]} key={country[1]}>
                  {country[0]}
                </option>
              );
            })}
          </select>
          {errors.country && <div className="invalid-feedback">{errors.country.join(", ")}</div>}
        </div>
      </div>
      {displayVatNumber && (
        <div className="row">
          <div className="form-group col-md-4 offset-md-8">
            <label>VAT number</label>
            <input
              type="text"
              value={vatNumber}
              onChange={(e) => setVatNumber(e.target.value)}
              className={`form-control ${hasErrors("vat_number") ? "is-invalid" : ""}`}
            />
            {errors.vat_number && <div className="invalid-feedback">{errors.vat_number.join(", ")}</div>}
          </div>
        </div>
      )}

      <div className="right">
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          Check
        </button>
      </div>
    </form>
  );
}

export default function AddToShoppingCartButton({ checkMembership, countries, countriesWithVat }) {
  const [canAddToCart, setCanAddToCart] = useState(!checkMembership);

  const disabledButton = checkMembership && !canAddToCart;

  return (
    <div>
      {checkMembership && !canAddToCart && (
        <MembershipValidation
          countries={countries}
          countriesWithVat={countriesWithVat}
          onValidationSuccess={() => setCanAddToCart(true)}
        />
      )}
      {checkMembership && canAddToCart && (
        <p className="right">Thank you. You can now add the Thinka to your cart.</p>
      )}
      {!disabledButton && (
        <div className="right">
          <input type="submit" value="Add to shopping cart" disabled={disabledButton} className="btn buy" />
        </div>
      )}
    </div>
  );
}
