import React from "react";
import ReactDOM from "react-dom";

import "bootstrap";
import "../stylesheets/application";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
});

function renderReactComponents() {
  const components = document.querySelectorAll("[data-react]");

  components.forEach((component) => {
    const reactComponent = require(`../components/${component.dataset.component}`);
    const props = JSON.parse(component.dataset.props);
    const reactElement = React.createElement(reactComponent.default, props);

    ReactDOM.render(reactElement, component);
  });
}

window.addEventListener("DOMContentLoaded", function () {
  renderReactComponents();
});

const changeAmount = (element, difference, allowZero = true) => {
  const input = $(element).siblings("input");
  const current = parseInt(input.val()) || 1;
  const newValue = allowZero ? current + difference : current + difference || 1;
  input.val(newValue);
  return input.val();
};

const getSearchParam = (param) => {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.get(param);
};

const initNew = (isReseller) => {
  const $productForm = $('form[name="product-form"]');
  const url = isReseller ? "/resellers/orders/update_product_price" : "/orders/update_product_price";
  const updateProductPrice = (amount, product) => {
    $.ajax({
      type: "POST",
      data: $productForm.serialize(),
      url,
    });
  };

  $(".less").on("click", function () {
    const amount = changeAmount(this, -1, isReseller);
    updateProductPrice(amount, getSearchParam("product"));
  });

  $(".more").on("click", function () {
    const amount = changeAmount(this, 1, isReseller);
    updateProductPrice(amount, getSearchParam("product"));
  });
};

const initCheckout = () => {
  const $newOrderForm = $("[id^=edit_order_]");
  const updateShippingEqualsBilling = () => {
    const shippingEqualsBilling = $("#order_shipping_equals_billing").is(":checked");
    $("#billing_address").toggleClass("hide", shippingEqualsBilling);
  };
  const updateMyOrder = () => {
    return $.ajax({
      type: "POST",
      data: $newOrderForm.serialize(),
      url: $newOrderForm.data("summary-url"),
      dataType: "script",
    });
  };
  if ($("#billing_address").length > 0) {
    $("#order_shipping_equals_billing").on("change", () => {
      updateShippingEqualsBilling();
    });
    updateShippingEqualsBilling();
  }

  if ($newOrderForm.length > 0) {
    $newOrderForm.on("change", () => {
      updateMyOrder().then(() => {
        renderReactComponents();
      });
    });
  }
};

$(() => {
  switch (location.pathname) {
    case "/resellers/orders":
    case "/orders":
      initNew(location.pathname.includes("resellers"));
      initCheckout();
      break;
    case "/orders/new":
    case "/resellers/orders/new":
      initNew(location.pathname.includes("resellers"));
      break;
    case "/orders/checkout":
    case "/resellers/orders/checkout":
      initCheckout();
      break;
    default:
      return;
  }
});
