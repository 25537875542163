import React, { useState } from "react";
import CartLineItem from "./CartLineItem";
import { fetchPost } from "../util/fetch";

export default function Cart(props) {
  const { totalValue, updateCartUrl, numberOfProducts, cartLineItems } = props;
  const [total, setTotal] = useState(totalValue);
  const [number, setNumber] = useState(numberOfProducts);
  const [lineItems, setLineItems] = useState(cartLineItems);

  const productsWithCounts = lineItems.reduce((memo, lineItem) => {
    memo[lineItem.product.code] = memo[lineItem.product.code] || 0;
    memo[lineItem.product.code] += lineItem.total_items;
    return memo;
  }, {});

  async function updateCart({ code, amount }) {
    const payload = { ...productsWithCounts, [code]: productsWithCounts[code] + amount };
    const updatedData = { amount: payload };
    const { cart_line_items, total_value, number_of_products } = await fetchPost(updateCartUrl, updatedData);

    setTotal(total_value);
    setLineItems(cart_line_items);
    setNumber(number_of_products);
    document.getElementById("cart_number_of_items").innerHTML = number_of_products;
  }

  return (
    <>
      <div className="row" id="cart">
        <div className="cart-container col-md-12">
          {lineItems.map((lineItem, index) => (
            <CartLineItem
              key={lineItem.product.id}
              product={lineItem.product}
              lineItems={lineItem.line_items}
              totalItems={lineItem.total_items}
              totalPrice={lineItem.total_price}
              updateCart={updateCart}
            />
          ))}
          <div className="row product pt-3 pb-3 pt-md-5 pb-md-5">
              <div className="col-md-6 col-lg-7 text-end">
                VAT and shipping cost will be calculated on the next page.
                <br /> Shipping twice a week
              </div>
              <div className="col-md-6 col-lg-5 d-flex justify-content-end">
                <div className="total-price text-end pt-4 pt-md-0 me-2">Total</div>
                <div className="total-price pt-4 pt-md-0 text-end">{total}</div>
              </div>
            </div>
        </div>
      </div>
      <div className="d-flex justify-content-end" style={{ marginLeft: -12, marginRight: -12 }}>
        <button type="submit" className="btn buy mt-3 mt-lg-4 mb-md-3 mb-lg-4" disabled={number < 1}>
          Checkout
        </button>
      </div>
    </>
  );
}
