import React from "react";

export default function PaymentMethod({ identifier, method, fee, limit, checked }) {
  if (identifier == "applepay" && (!window.ApplePaySession || !window.ApplePaySession.canMakePayments())) {
    return null;
  }

  return (
    <div className="radio">
      <label>
        <input
          type="radio"
          defaultChecked={checked}
          name="order[payment_method]"
          id={`order_payment_method_${identifier}`}
          value={identifier}
          disabled={!!limit}
        />
        <span>
          {method.name} {fee && <span>{fee}</span>} {limit && <span>{limit}</span>}
        </span>
      </label>
    </div>
  );
}
